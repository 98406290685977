import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { useDateFormatter, type DateFormatterOptions } from "react-aria";

/*
{
  weekday: 'narrow' | 'short' | 'long',
  era: 'narrow' | 'short' | 'long',
  year: 'numeric' | '2-digit',
  month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
  day: 'numeric' | '2-digit',
  hour: 'numeric' | '2-digit',
  minute: 'numeric' | '2-digit',
  second: 'numeric' | '2-digit',
  timeZoneName: 'short' | 'long',

  // Time zone to express it in
  timeZone: 'Asia/Shanghai',
  // Force 12-hour or 24-hour
  hour12: true | false,

  // Rarely-used options
  hourCycle: 'h11' | 'h12' | 'h23' | 'h24',
  formatMatcher: 'basic' | 'best fit'
}
*/

export type DateLike = string | Date;

function toDate(date: DateLike) {
    return date instanceof Date ? date : apiDatetimeToDate(date);
}

export interface DateProps {
    date: DateLike;
    forceUtc?: boolean;
}

function useFormattedDate(options: DateFormatterOptions, { date, forceUtc }: DateProps) {
    const formatter = useDateFormatter({ ...options, timeZone: forceUtc ? "UTC" : undefined });
    return formatter.format(toDate(date));
}

const longOptions: DateFormatterOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "shortGeneric",
};

export function LongDate(props: DateProps) {
    return useFormattedDate(longOptions, props);
}

const shortOptions: DateFormatterOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
};

export function ShortDate(props: DateProps) {
    return useFormattedDate(shortOptions, props);
}
